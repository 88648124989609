/** @format */

import has from 'lodash/has'
import { createRef, Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { RemoveIcon } from 'components/shared/svg-icons'

const noop = function() {}

export class TextField extends Component {
  static propTypes = {
    fieldName: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.string,

    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    hintText: PropTypes.string,
    title: PropTypes.string,

    clearable: PropTypes.bool,

    hasError: PropTypes.bool,
    // errorText: PropTypes.string,
    errorTitle: PropTypes.string,

    focus: PropTypes.bool,
    focusDelay: PropTypes.number,

    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyPress: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
  }

  static defaultProps = {
    type: 'text',
    maxLength: 150,

    clearable: false,

    hasError: false,

    focus: false,
    focusDelay: 100,

    onBlur: noop,
    onChange: noop,
    onFocus: noop,
    onKeyPress: noop,
    onKeyDown: noop,
    onKeyUp: noop,
  }

  componentDidMount() {
    this.props.focus && this.focus()
  }

  inputRef = createRef()

  focus() {
    setTimeout(() => {
      if (this.inputRef.current) {
        this.inputRef.current.focus()
        this.inputRef.current.value = this.inputRef.current.value
      }
    }, this.props.focusDelay)
  }

  onClear = () => this.props.onChange(null)

  onChange = ev => this.props.onChange(ev.target.value)

  render() {
    const {
      className,
      modifier,
      defaultValue,
      value,
      fieldName,
      inputId,
      iconLeft: IconLeft,
      clearable,
      hintText,
      errorTitle,
      title,
      hasError,
      errorText,
      focus, // eslint-disable-line no-unused-vars
      focusDelay, // eslint-disable-line no-unused-vars
      ...rest
    } = this.props

    let addProps = {}
    let realValue

    if (has(this.props, 'defaultValue')) {
      addProps.defaultValue = defaultValue
      realValue = defaultValue || ''
    }

    if (has(this.props, 'value')) {
      addProps.value = value || ''
      realValue = addProps.value
    }

    return (
      <div
        className={cn(
          'TextField',
          { [`TextField--${modifier}`]: modifier, 'TextField--has-error': hasError },
          className
        )}
      >
        <div className={cn('TextField__control', { 'TextField__control--clearable': clearable })}>
          {IconLeft && (
            <span className="TextField__leftIcon">
              {typeof IconLeft == 'string' ? <i className={IconLeft} /> : IconLeft}
            </span>
          )}

          <input
            id={inputId}
            ref={this.inputRef}
            name={fieldName}
            placeholder={hintText}
            title={hasError ? errorTitle || title : title}
            {...addProps}
            {...rest}
            onChange={this.onChange}
          />

          {clearable && realValue.length !== 0 && (
            <s onClick={this.onClear} className="TextField__clear">
              <RemoveIcon size={14} fill={null} />
            </s>
          )}
        </div>

        {hasError && errorText && <span className="TextField--error-text">{errorText}</span>}
      </div>
    )
  }
}

export class InlineTextField extends Component {
  inputRef = createRef()

  focus() {
    this.inputRef.current?.focus()
  }

  render() {
    return <TextField ref={this.inputRef} modifier="inline" {...this.props} />
  }
}
