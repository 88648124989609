/** @format */
/* global I18n */

import React, { Component } from 'react'

import { httpPost, flash, processResponse } from 'src/lib/utils'

import { Row, Col } from 'components/shared/base'
import { Button, TextField } from 'components/shared/forms'
import { ParagraphLight } from 'components/shared/typography'

import {
  ButtonRow,
  CharTypesList,
  Checklist,
  ChecklistItem,
  ChecklistRow,
  Eye,
  Form,
  FormRow,
  Header,
  Input,
  Label,
} from './styled'

const scope = 'users.change_password'

const REQUEST = {
  method: 'post',
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  body: { validate: true },
}
const APIS = {
  userId: {
    changePassword: (userId, payload) => httpPost(`/api/users/${userId}/password`, payload),
    validate: (userId, payload) =>
      httpPost(`/api/users/${userId}/password`, { ...payload, validate: true }),
  },

  token: {
    changePassword: (token, payload) => {
      const body = JSON.stringify(payload)

      return fetch(`/api/users/${token}/password`, { ...REQUEST, body }).then(processResponse)
    },
    validate: (token, payload) => {
      const body = JSON.stringify({ ...REQUEST.body, ...payload })

      return fetch(`/api/users/${token}/password`, { ...REQUEST, body }).then(processResponse)
    },
  },
}

export default class UserPassword extends Component {
  static defaultProps = {
    token: null,
  }

  state = {
    current: null,
    errors: {},
    password: null,
    showPassword: false,
  }

  api = APIS[this.props.token ? 'token' : 'userId']

  touched = {}

  onChange(key, value) {
    this.touched[key] = true
    this.setState({ [key]: value }, async () => {
      this.setState({ errors: await this.validate() })
    })
  }

  onPasswordBlur = () => {
    if (this.state.password == '') {
      delete this.touched.password
      this.setState({ errors: {} })
    }
  }

  onSubmit = ev => {
    ev.preventDefault()

    this.valid() && this.submit()
  }

  onToggleHidePassword = () => this.setState({ showPassword: !this.state.showPassword })

  async validate() {
    let errors = {}

    this.validateCurrentPassword(errors)
    this.validatePasswordLength(errors)

    const { current, password } = this.state
    const arg = this.props.token || this.props.userId

    if (password && password.length >= 3) {
      try {
        await this.api.validate(arg, { password: current, newPassword: password })
      } catch (error) {
        if (error.response && error.response.status == 422) {
          const payload = await error.response.json()

          errorsIterator(payload.errors, (key, err) => {
            if (err.code == 1051) {
              errors.complexity = true
            }
            if (err.code == 1053) {
              errors.recent = true
            }
          })
        }
      }
    }

    return errors
  }

  valid() {
    const { errors, password } = this.state

    return password && Object.keys(errors).length == 0
  }

  validateCurrentPassword(errors) {
    if (!this.props.token && !this.state.current) {
      errors.current = I18n.t('errors.missing_current', { scope })
    }
  }

  validatePasswordLength(errors) {
    const { passwordMinLength } = this.props
    const { password } = this.state

    if (this.touched.password && (password || '').length < passwordMinLength) {
      errors.minLength = true
    }
  }

  submit() {
    const { current, password } = this.state
    const arg = this.props.token || this.props.userId

    this.api
      .changePassword(arg, {
        password: current,
        newPassword: password,
      })
      .then(() => {
        this.props.token ? (window.location.href = '/') : this.done()
      })
      .catch(error => {
        if (error.response?.status == 422) {
          error.response.json().then(({ errors }) => {
            const errCode = errors.password && errors.password[0].code

            if (errCode == 1052) {
              flash(I18n.t('errors.invalid', { scope }), 'error')
              this.setState({ current: '' })
            } else if (errCode == 1054) {
              flash(I18n.t('errors.often', { scope }), 'error')
              this.setState({ current: '' })
            } else {
              throw error
            }
          })
        } else {
          throw error
        }
      })
      .catch(() => {
        flash(I18n.t('messages.errors.general'), 'error')
      })
  }

  done() {
    flash(I18n.t('messages.success.changed'))
    this.props.onClose()
  }

  /**
   * VIEW
   */

  renderErrorIcon(name) {
    return this.touched.password && this.state.password.length > 3 ? (
      <i className={`icon-${name ? 'remove' : 'ok'}-sign`} />
    ) : (
      <i className="icon-question-sign" />
    )
  }

  render() {
    const { firstTimeLogin, passwordMinComplexity, passwordMinLength } = this.props
    const { current, errors, password, showPassword } = this.state

    return (
      <section>
        <Row>
          <Col lg={5} sm={12}>
            <Header>{I18n.t(`title${firstTimeLogin ? '_first_login' : ''}`, { scope })}</Header>

            <Form onSubmit={this.onSubmit}>
              {!this.props.token && (
                <FormRow>
                  <Label>{I18n.t('current_password', { scope })}</Label>
                  <Input>
                    <TextField
                      autoComplete="current-password"
                      errorText={errors.current}
                      hasError={!!errors.current}
                      style={{ display: 'inline-block' }}
                      type="password"
                      value={current}
                      onChange={this.onChange.bind(this, 'current')}
                    />
                  </Input>
                </FormRow>
              )}

              <FormRow>
                {firstTimeLogin ? (
                  <p>{I18n.t('set_password_and_begin', { scope })}</p>
                ) : (
                  <Label>{I18n.t('password', { scope })}</Label>
                )}
                <Input>
                  <TextField
                    autoComplete="new-password"
                    style={{ display: 'inline-block' }}
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={this.onChange.bind(this, 'password')}
                    onBlur={this.onPasswordBlur}
                  />
                  <Eye state={showPassword} onClick={this.onToggleHidePassword} />
                </Input>
              </FormRow>

              <ButtonRow>
                <Button disabled={!this.valid()} onClick={this.onSubmit}>
                  {I18n.t(`button${firstTimeLogin ? '_first_login' : ''}`, { scope })}
                </Button>
              </ButtonRow>
            </Form>
          </Col>

          <Col lg={6} sm={12} push={1} end>
            <ParagraphLight>{I18n.t('requirements.title', { scope })}:</ParagraphLight>

            <Checklist>
              <ChecklistItem>
                <ChecklistRow>
                  {this.renderErrorIcon(errors.minLength)}
                  {I18n.t('requirements.length', { scope, length: passwordMinLength })}
                </ChecklistRow>
              </ChecklistItem>

              <ChecklistItem className="flex-col">
                <ChecklistRow>
                  {this.renderErrorIcon(errors.complexity)}
                  {I18n.t(
                    `requirements.complexity.${passwordMinComplexity > 3 ? 'all' : 'other'}`,
                    { scope, required: passwordMinComplexity }
                  )}
                  :
                </ChecklistRow>
                <CharTypesList>
                  {['capital', 'lowercase', 'number', 'special'].map(type => (
                    <li key={type}>{I18n.t(`requirements.char_types.${type}`, { scope })}</li>
                  ))}
                </CharTypesList>
              </ChecklistItem>

              <ChecklistItem>
                <ChecklistRow>
                  {this.renderErrorIcon(errors.recent)}
                  {I18n.t('requirements.recent', { scope })}
                </ChecklistRow>
              </ChecklistItem>
            </Checklist>
          </Col>
        </Row>
      </section>
    )
  }
}

/**
 * HELPERS
 */

function errorsIterator(errors, callback) {
  return Object.keys(errors).forEach(key => {
    errors[key].forEach(err => callback(key, err))
  })
}
