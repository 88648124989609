import { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const noop = () => {}

export class Checkbox extends Component {
  static propTypes = {
    label:        PropTypes.string,
    labelOnLeft:  PropTypes.bool,
    disabled:     PropTypes.bool,

    onChange:     PropTypes.func,
  }

  static defaultProps = {
    label:        null,
    labelOnLeft:  false,
    disabled:     false,
    onChange:     noop,
  }

  constructor(props) {
    super(props)

    this.state = {
      checked:  !!props.checked,
      hover:    false,
    }
  }

  handleHover(state) {
    this.setState({ hover: state })
  }

  onChange(ev) {
    ev.stopPropagation()

    if (!this.props.disabled)
      this.props.onChange(!this.props.checked)
  }

  render() {
    const { checked, label, labelOnLeft, disabled } = this.props
        , { hover } = this.state

    let additionalAttributes = disabled ? {} : {
          onClick:      this.onChange.bind(this),
          onMouseOver:  this.handleHover.bind(this, true),
          onMouseLeave: this.handleHover.bind(this, false),
        }

    return (
      <span className={classnames('checkbox', { 'checkbox--disabled': disabled })}
            title={this.props.title}
            {...additionalAttributes}>
        {label && labelOnLeft &&
          <span className="checkbox__label checkbox__label--left">{label}</span>}
        <i className={classnames({ 'icon-p-checkbox': !checked && !hover,
                                   'icon-p-checkbox-checked': checked || hover,
                                   'is-checked': checked,
                                   'has-hover': hover })} />
        {label && !labelOnLeft &&
          <span className="checkbox__label checkbox__label--right">{label}</span>}
      </span>
    )
  }
}
