const white = '#fff'
const orange = '#e67f22'
const green = '#9cd245'
const purple = '#a87eea'
const blue = '#006ac5'
const text = '#849098'

export default {
  black: '#000000',
  blue,
  red: '#c20b19',
  white,
  yellow: '#fee100',
  green,
  orange,
  purple,

  low: '#ffe100',
  high: '#e67f22',
  urgent: '#c20b19',

  text,

  bg: white, // paper
  lightBg: '#ecf0f1',
  mediumBg: '#c0cbce',
  disabledButtonBg: '#b4bac0',

  get tableRowOdd() {
    return this.bg
  },
  tableRowEven: '#f8f8f8',
  rowHover: '#fbeeb7',

  inputBorder: '#ccc',
  inputBorderHover: text,
  inputBorderFocus:  text, //#'#00b4eb',
  inputPlaceholder: '#aaa',

  allTasks: orange,
  allTasksShadow: '#d15400',
  defferedTasks: '#f2c510',
  defferedTasksShadow: '#f4ab10',
  doneTasks: green,
  doneTasksShadow:'#74ae4a',
  myTasks: '#00baff',
  myTasksShadow: '#0978d2',
  solvedTasks: purple,
  solvedTasksShadow: '#8154c8',

  // gantt
  barGray: '#bec4c8',

  // from react-select
  primary: '#2684ff',
}
