// mount

(function(document, window) {
  // jQuery is optional. Use it to support legacy browsers.
  var $ = (typeof window.jQuery !== 'undefined') && window.jQuery;

  window.ReactRailsUJS = {
    // This attribute holds the name of component which should be mounted
    // example: `data-react-class="MyApp.Items.EditForm"`
    CLASS_NAME_ATTR: 'data-react-class',

    // This attribute holds JSON stringified props for initializing the component
    // example: `data-react-props="{\"item\": { \"id\": 1, \"name\": \"My Item\"} }"`
    PROPS_ATTR: 'data-react-props',

    // helper method for the mount and unmount methods to find the
    // `data-react-class` DOM elements
    findDOMNodes: function(searchSelector) {
      // we will use fully qualified paths as we do not bind the callbacks
      var selector, parent;

      switch (typeof searchSelector) {
        case 'undefined':
          selector = '[' + window.ReactRailsUJS.CLASS_NAME_ATTR + ']';
          parent = document;
          break;
        case 'object':
          selector = '[' + window.ReactRailsUJS.CLASS_NAME_ATTR + ']';
          parent = searchSelector;
          break;
        case 'string':
          selector = searchSelector + '[' + window.ReactRailsUJS.CLASS_NAME_ATTR + '], ' +
                     searchSelector + ' [' + window.ReactRailsUJS.CLASS_NAME_ATTR + ']';
          parent = document;
          break
        default:
          break;
      }

      if ($) {
        return $(selector, parent);
      } else {
        return parent.querySelectorAll(selector);
      }
    },

    // Within `searchSelector`, find nodes which should have React components
    // inside them, and mount them with their props.
    mountComponents: function(searchSelector) {
      var nodes = window.ReactRailsUJS.findDOMNodes(searchSelector);

      for (var i = 0; i < nodes.length; ++i) {
        var node = nodes[i];
        var className = node.getAttribute(window.ReactRailsUJS.CLASS_NAME_ATTR);

        // Assume className is simple and can be found at top-level (window).
        // Fallback to eval to handle cases like 'My.React.ComponentName'.
        var constructor = window[className] || eval.call(window, className);
        var propsJson = node.getAttribute(window.ReactRailsUJS.PROPS_ATTR);
        var props = propsJson && JSON.parse(propsJson);

        // Prefer ReactDOM if defined (introduced in 0.14)
        var renderer = (typeof ReactDOM == "object") ? ReactDOM : React;

        renderer.render(React.createElement(constructor, props), node);
      }
    },

    // Within `searchSelector`, find nodes which have React components
    // inside them, and unmount those components.
    unmountComponents: function(searchSelector) {
      var nodes = window.ReactRailsUJS.findDOMNodes(searchSelector);

      for (var i = 0; i < nodes.length; ++i) {
        var node = nodes[i];

        // Prefer ReactDOM if defined (introduced in 0.14)
        var renderer = (typeof ReactDOM == "object") ? ReactDOM : React;
        renderer.unmountComponentAtNode(node);
      }
    }
  };
})(document, window);

// native

(function(document, window) {
  // jQuery is optional. Use it to support legacy browsers.
  var $ = (typeof window.jQuery !== 'undefined') && window.jQuery;

  window.ReactRailsUJS.Native = {
    // Attach handlers to browser events to mount & unmount components
    setup: function() {
      if ($) {
        $(function() {window.ReactRailsUJS.mountComponents()});
      } else if ('addEventListener' in window) {
        document.addEventListener('DOMContentLoaded', function() {window.ReactRailsUJS.mountComponents()});
      } else {
        // add support to IE8 without jQuery
        window.attachEvent('onload', function() {window.ReactRailsUJS.mountComponents()});
      }
    }
  };
})(document, window);

// event setup

(function(document, window) {
  // jQuery is optional. Use it to support legacy browsers.
  // var $ = (typeof window.jQuery !== 'undefined') && window.jQuery;
  // if ($) {
  //   ReactRailsUJS.handleEvent = function(eventName, callback) {
  //     $(document).on(eventName, callback);
  //   };
  // } else {
    ReactRailsUJS.handleEvent = function(eventName, callback) {
      document.addEventListener(eventName, callback);
    };
  // }
  // Detect which kind of events to set up:
  // if (typeof Turbolinks !== 'undefined' && Turbolinks.supported) {
  //   if (typeof Turbolinks.EVENTS !== 'undefined') {
  //     // Turbolinks.EVENTS is in classic version 2.4.0+
  //     ReactRailsUJS.TurbolinksClassic.setup();
  //   } else if (typeof Turbolinks.controller !== "undefined") {
  //     // Turbolinks.controller is in version 5+
  //     ReactRailsUJS.Turbolinks.setup();
  //   } else {
  //     ReactRailsUJS.TurbolinksClassicDeprecated.setup();
  //   }
  // } else {
  //   ReactRailsUJS.Native.setup();
  // }
})(document, window);
