import { Component } from 'react'
import { TextField } from '.'

export class TimeInput extends Component {
  constructor(props) {
    super(props)

    const minutes = props.value ? this.parseTime(props.value.toString()) : null

    this.state = {
      value:          minutes ? this.toTime(minutes) : '',
      minutes,
      errormessage:   null,
      error:          false,
    }
  }

  parseByRegex(value) {
    let matches = value.match(/^\s*(\d+)(?:h\s?|:)(\d+)+m?/)

    if (matches) {
      return parseInt(matches[1]) * 60 + parseInt(matches[2])
    }

    matches = value.match(/(\d+)m/)

    if (matches)  {
      return parseInt(matches[1])
    }

    matches = value.match(/^\s*(\d+)\s*$/)

    if (matches)  {
      return parseInt(matches[1] * 60)
    }
  }

  parseTime(value) {
    let minutes = this.parseByRegex(value)

    if (!minutes) {
      let result = value.replace(',', '.')

      if (isNaN(parseFloat(result))) {
        return false
      } else {
        return parseFloat(result) * 60
      }
    } else {
      return minutes
    }
  }

  toTime(minutes) {
    let h = Math.floor(minutes / 60)
    let m = Math.round(minutes % 60)

    h = h < 10 ? '0' + h : h
    m = m < 10 ? '0' + m : m

    return h + ':' + m
  }

  onChange = value => {
    this.setState({ value })
  }

  onKeyDown = ev => {
    let minutes = this.state.minutes

    if (ev.key == 'ArrowUp') {
      if (minutes === null)  {
        minutes = 0
      }

      minutes += 15

      this.setState({ minutes, value: this.toTime(minutes) })
    } else if (ev.key == 'ArrowDown') {
      if (minutes === null)  {
        minutes = 480
      } else {
        minutes -= 15
      }

      if (minutes <= 0) {
        minutes = 0
      }

      this.setState({ minutes, value: this.toTime(minutes) })
    }
  }

  onBlur = () => {
    const minutes = this.parseTime(this.state.value)

    if (minutes) {
      this.props.onChange(minutes / 60)

      let value = this.toTime(minutes)

      this.setState({
        minutes, value, error: false,
      })
    } else {
      this.setState({
        error: true,
      })
    }
  }

  onKeyPress = ev => {
    if (ev.key === 'Enter') {
      this.onBlur()
    }
  }

  onUpClick = () => {
    let minutes = this.state.minutes

    if (minutes === null)  {
      minutes = 0
    }

    minutes += 15

    this.setState({ minutes, value: this.toTime(minutes) })
    this.props.onChange(minutes / 60)
  }

  onDownClick = () => {
    let minutes = this.state.minutes

    if (minutes === null)  {
      minutes = 480
    } else {
      minutes -= 15
    }

    if (minutes <= 0) {
      minutes = 0
    }

    this.setState({ minutes, value: this.toTime(minutes) })
    this.props.onChange(minutes / 60)
  }

  onWheel = ev => {
    ev.preventDefault()

    let minutes = this.state.minutes

    if (ev.deltaY > 0) {
      if (minutes === null)  {
        minutes = 480
      }

      minutes -= 5

      if (minutes <= 0) {
        minutes = 0
      }

      this.setState({ minutes, value: this.toTime(minutes) })
    } else if (ev.deltaY < 0) {
      if (minutes === null)  {
        minutes = 480
      }

      minutes += 5

      this.setState({ minutes, value: this.toTime(minutes) })
    }
  }

  render() {
    return  (
      <span className="TimeInput">
        <TextField
          autoComplete="off"
          className="TimeInput__input"
          required
          value={this.state.value}
          onChange={this.onChange}
          onKeyPress={this.handleKeyPress}
          onKeyDown={this.onKeyDown}
          onBlur={this.onBlur}
          onWheel={this.onWheel}
        />
        
        {this.state.error && <p className="TimeInput__error">{this.state.errormessage}</p>}
        
        <i className="TimeInput__button icon-chevron-up" onClick={this.onUpClick} />
        <i className="TimeInput__button icon-chevron-down" onClick={this.onDownClick} />
      </span>
    )
  }
}

export class RailsTimeInput extends Component {
  state = {
    value:    this.props.value,
    result:   null,
  }

  onChange = value => {
    this.setState({ value })
  }

  render()  {
    return  (
      <div className="TimeInput_wrap">
        <TimeInput {...this.props} onChange={this.onChange} />
        <input type="hidden" name={this.props.name} value={this.state.value || ''} required={this.props.required} />
      </div>
    )
  }
}
