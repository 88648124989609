/** @format */

import { Component } from 'react'
import PropTypes from 'prop-types'
import { DateRangePicker } from 'react-dates'
import cn from 'classnames'
import styled from 'styled-components'

import I18n from 'src/i18n'
import { guid } from 'src/lib/utils'

import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'

export class DateRangeField extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    clearable: PropTypes.bool,
    modifier: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    className: '',
    clearable: false,
    endDatePlaceholderText: null,
    startDatePlaceholderText: null,
    // interactions
    isOutsideRange: () => false,
  }

  constructor(props) {
    super(props)

    this.state = {
      focusedInput: this.props.focusedInput,
      id: this.props.id || guid(),
      endDate: this.convertToMoment(this.props.startDate),
      startDate: this.convertToMoment(this.props.endDate),
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { startDate, endDate } = nextProps

    if (this.props.startDate != startDate || this.props.endDate != endDate) {
      this.setState({ startDate: this.convertToMoment(startDate), endDate: this.convertToMoment(endDate) })
    }
  }

  convertToMoment = (date) => {
    return date ? moment(date) : null
  }

  onDatesChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate })

    if (!startDate && !endDate) {
      this.props.onClose({ startDate, endDate })
    }
  }

  onFocusChange = focusedInput => {
    this.setState({ focusedInput })
  }

  render() {
    const {
      className,
      clearable,
      endDate: _endDate, // eslint-disable-line no-unused-vars
      id,
      modifier,
      onChange, // eslint-disable-line no-unused-vars
      startDate: _startDate, // eslint-disable-line no-unused-vars
      ...rest
    } = this.props
    const { focusedInput, startDate, endDate } = this.state

    return (
      <StyledDateRangeField
        className={cn('DateRangeField', className, { [`DateRangeField--${modifier}`]: modifier })}
      >
        <DateRangePicker
          daySize={30}
          displayFormat={I18n.t('date.moment_formats.default')}
          endDate={endDate}
          endDateId={`${id}_endDate`}
          focusedInput={focusedInput}
          hideKeyboardShortcutsPanel
          numberOfMonths={2}
          readOnly
          showClearDates={clearable}
          small
          startDate={startDate}
          startDateId={`${id}_startDate`}
          withPortal
          // callbacks
          onClose={this.onClose}
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          // other
          {...rest}
        />
      </StyledDateRangeField>
    )
  }
}

// NOTE: Not used yet so also not properly styled yet
// export const InlineDateRangeField = props => (
//   <DateRangeField {...props} modifier="inline" noBorder />
// )

const StyledDateRangeField = styled.div`
  display: inline-block;
`
