// this is for now the only way how you can subclass an Array
// see: https://thejsguy.com/2017/09/21/subclassing-arrays-in-es2015.html
// BUT! It's probably performace problem and their suggest to use class approach instead
// function Tree(...args) {
//   Object.setPrototypeOf(args, Tree.prototype)

//   return args
// }
// Tree.prototype = Object.create(Array.prototype)
// Tree.prototype.constructor = Tree
// Tree.prototype.flattenWithDepth = flattenWithDepth
class Tree extends Array {
  flattenWithDepth = flattenWithDepth
}

export function flattenWithDepth(depth=0, accumulator=[]) {
  this.forEach(({ id, children, source: record }) => {
    let hasChildren = children ? children.length > 0 : 0

    accumulator.push({ id, record, depth, hasChildren })

    hasChildren && (accumulator = children.flattenWithDepth(depth + 1, accumulator))
  })

  return accumulator
}

export default Tree
