/** @format */

import { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

export class Row extends Component {
  static defaultProps = {
    componentClass: 'div',
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.className != nextProps.className ||
      this.props.componentClass != nextProps.componentClass ||
      this.props.children != nextProps.children
    )
  }

  render() {
    const { componentClass: ComponentClass, className, ...rest } = this.props

    return <ComponentClass {...rest} className={cn('row', className)} />
  }
}

export const FormRow = ({ className, ...rest }) => {
  return <Row {...rest} className={cn('!mb-md', className)} />
}

export class Col extends Component {
  static propTypes = {
    sm: PropTypes.number,
    lg: PropTypes.number,

    smOffset: PropTypes.number,
    lgOffset: PropTypes.number,

    smCentered: PropTypes.bool,
    lgCentered: PropTypes.bool,
    smUncentered: PropTypes.bool,
    lgUncentered: PropTypes.bool,

    pull: PropTypes.number,
    push: PropTypes.number,

    end: PropTypes.bool,
  }

  static defaultProps = {
    componentClass: 'div',
  }

  render() {
    const {
      componentClass: ComponentClass,
      className,
      sm,
      lg,
      smOffset,
      lgOffset,
      smCentered,
      lgCentered,
      smUncentered,
      lgUncentered,
      pull,
      push,
      end,
      ...rest
    } = this.props

    let classes = []

    sm && classes.push(`small-${sm}`)
    lg && classes.push(`large-${lg}`)

    classes.push('columns')

    smOffset && classes.push(`small-offset-${smOffset}`)
    lgOffset && classes.push(`large-offset-${lgOffset}`)

    smCentered && classes.push('small-centered')
    lgCentered && classes.push('large-centered')
    smUncentered && classes.push('small-uncentered')
    lgUncentered && classes.push('large-uncentered')

    pull && classes.push(`pull-${pull}`)
    push && classes.push(`push-${push}`)

    end && classes.push('end')

    return <ComponentClass className={cn(classes, className)} {...rest} />
  }
}
