/** @format */

import styled from 'styled-components'
import colors from 'src/lib/colors'

export const ButtonRow = styled.div`
  margin: 0.5rem 0.5rem 2rem 0;
`

export const CharTypesList = styled.ul`
  padding-left: 1.5rem;
  margin-bottom: 0;
  line-height: 1.2rem;
`

export const Checklist = styled.div`
  list-style-type: none;
  font-size: 0.95rem;
  display: flex;
  flex-direction: column;
`

export const ChecklistItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;

  i {
    font-size: 1.25rem;
    margin-right: 0.5rem;

    &.icon-ok-sign {
      color: green;
    }

    &.icon-question-sign {
      color: ${colors.text};
    }

    &.icon-remove-sign {
      color: red;
    }
  }
`

export const ChecklistRow = styled.div`
  display: flex;
  align-items: center;
`

export const Eye = ({ state, ...rest }) => {
  return <StyledEyeIcon className={`icon-eye-${state ? 'close' : 'open'}`} {...rest} />
}

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`

export const FormRow = styled.div`
  margin: 0.5rem 0;
`

export const Header = styled.h2``

export const Input = styled.div`
  position: relative;
  margin: 0.5rem 0;

  .TextField {
    input {
      color: ${colors.black};
      margin: 0;
    }
  }
`

export const Label = styled.label`
  color: ${colors.black};
  text-align: left !important;
`

/* PRIVATE */

const StyledEyeIcon = styled.i`
  position: absolute;
  top: 11px;
  right: 8px;
  font-size: 1.05em;
  color: ${colors.text};
  cursor: pointer;
`
