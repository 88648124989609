/** @format */

import styled from 'styled-components'
import colors from 'src/lib/colors'

export const ParagraphDark = styled.div`
  color: ${colors.black};
  font-weight: 300;
  margin-bottom: 1rem;
  line-height: 1.5rem;
`

export const ParagraphLight = styled.div`
  color: ${colors.text};
  font-weight: 300;
  margin-bottom: 1rem;
  line-height: 1.5rem;
`
