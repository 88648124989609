/** @format */

import { forwardRef } from 'react'
import cn from 'classnames'

const ActionButton = forwardRef(
  ({ children, fontSize, Component = 'button', icon: Icon, color, ...rest }, ref) => (
    <Component
      {...rest}
      className={cn(
        'w-[26px] h-[26px] inline-flex items-center justify-center bg-gray-100 border border-gray-base/50 rounded-md center text-black/50 hover:bg-gray-300 hover:text-black hover:border-gray-base/70',
        {
          'hover:text-red-base': color == 'red',
        }
      )}
      ref={ref}
    >
      {typeof Icon == 'string' && <i className={Icon} />}
      {typeof Icon != 'string' && <Icon fontSize="lg" />}
      {!Icon && children}
    </Component>
  )
)

export default ActionButton
