/** @format */

import { forwardRef } from 'react'
import cn from 'classnames'

const Button = forwardRef(
  (
    {
      Component = 'button',
      children,
      className,
      color = 'blue',
      disabled = false,
      size = 'md',
      startIcon,
      variant = 'contained', // outlined, text
      lowercase = false,
      ...rest
    },
    ref
  ) => {
    function onClick(ev) {
      disabled || (rest.onClick && rest.onClick(ev))
    }

    const attr = disabled
      ? {
          'bg-gray-base/90 text-white': variant == 'contained',
          'bg-gray-base/10 border border-gray-base/50 text-gray-base': variant == 'outlined',
          'bg-gray-base/10 text-gray-base': variant == 'text',
        }
      : {
          'bg-blue-base hover:bg-blue-dark text-white': variant == 'contained' && color == 'blue',
          'bg-red-base hover:bg-red-dark text-white': variant == 'contained' && color == 'red',
          'bg-green-base hover:bg-green-dark text-white':
            variant == 'contained' && color == 'green',
          'border border-gray-base/50 hover:border-gray-base hover:bg-gray-base/10 text-gray-500 hover:text-gray-600':
            variant == 'outlined' && (color == 'blue' || color == 'gray'),
          'border border-gray-base/50 hover:border-red-base hover:bg-red-light/10 text-gray-500 hover:text-red-dark':
            variant == 'outlined' && color == 'red',
          'border border-gray-base/50 hover:border-green-base hover:bg-green-light/20 text-gray-500 hover:text-green-dark':
            variant == 'outlined' && color == 'green',
          'bg-gray-base hover:bg-blue-base text-white': variant == 'contained' && color == 'gray',
          'text-gray-500 hover:text-gray-600 hover:bg-gray-base/10':
            variant == 'text' && (color == 'blue' || color == 'gray'),
          'text-gray-500 hover:text-red-dark hover:bg-red-light/10':
            variant == 'text' && color == 'red',
          'text-gray-500 hover:text-green-dark hover:bg-green-light/20':
            variant == 'text' && color == 'green',
        }

    return (
      <Component
        ref={ref}
        className={cn(
          'appearance-none inline-flex items-center min-w-fit px-sm py-xs text-base leading-none transition-colors duration-200 ease-in-out lowercase',
          {
            'shadow-sm shadow-black/20': variant == 'contained',
            'h-[26px]': size == 'sm',
            'h-[32px]': size == 'md',
            'h-[35px]': size == 'lg',
            'lowercase': lowercase,
            ...attr,
          },
          className
        )}
        disabled={disabled}
        type="button"
        {...rest}
        onClick={onClick}
      >
        {startIcon && (
          <span className="inline-flex items-center relative h-[24px] mr-sm">{startIcon}</span>
        )}
        {children}
      </Component>
    )
  }
)

export default Button
