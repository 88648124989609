import last from 'lodash/last'
import { List, Map } from 'immutable'
import Tree from './tree'

/**
 * @param {Array} arr
 */
export function ProjectList(arr=[]) {
  if (arr instanceof Map) {
    arr = arr.toList()
  } else {
    arr = List(arr)
  }

  if (!Object.keys(arr).includes('toTree')) {
    arr.toTree = toTree
    arr.toNodes = toNodes
  }

  return arr
}

/**
 * @param {Array} arr
 */
export function TaskList(arr=[]) {
  if (arr instanceof Map) {
    arr = arr.toList()
  } else {
    arr = List(arr)
  }

  if (!Object.keys(arr).includes('toTree')) {
    arr.toTree = toTree
    arr.toNodes = toNodes
  }

  return arr
}

/**
 * Converts List of Tasks or List of Projects to flat Array w/ structure compatible w/ Tree
 */
export function toNodes() {
  return this.map(node => ({ id: node.id, children: [], source: node })).toArray()
}

function prepareObjects(list) {
  return [
    new Tree(),
    list.sortBy(n => n.lft).groupBy(n => n.rootId || 0),
    list.reduce((a, e) => {
      a[e.id] = { id: e.id, children: new Tree(), source: e }

      return a
    }, {}),
  ]
}

/**
 * Converts List of Tasks or List of Projects to Tree
 */
export function toTree() {
  let [roots, nodesByLft, lookup] = prepareObjects(this)

  this.forEach(node => {
    const ourNode = lookup[node.id]
    const parentNode = lookup[getClosestNodeParentId(nodesByLft.get(node.rootId || 0), node)]

    if (!parentNode) {
      // there is not visible parent or visible parent not in lookup table, add this one as root node
      roots.push(ourNode)
    } else {
      parentNode.children.push(ourNode)
    }
  })

  return roots
}

export function getClosestNodeParentId(nodesByLft, node) {
  let ancestors = []

  for (let i = 0; i < nodesByLft.size; i++) {
    const n = nodesByLft.get(i)

    if (n.lft >= node.lft) {
      break
    }

    n.rgt > node.rgt && node.rootId == n.rootId && ancestors.push(n)
  }

  return last(ancestors)?.id
}
